<template>
  <fast-cards-pay
      v-if="orderNo"
      :show="true"
      :order-no="orderNo"
      :closeable="false"
      :show-cancel="false"
      @success="onSuccess"
  />
</template>

<script>
import FastCardsPay from '@/components/FastCardsPay.vue';
import { Dialog } from 'vant';

export default {
  name: 'pcFastQrCodePay',
  components: { FastCardsPay },
  data() {
    return {
      orderNo: '',
    };
  },
  created() {
    let { orderNo = '' } = this.$route.query;
    this.orderNo = orderNo;
  },
  methods: {
    onSuccess() {
      this.orderNo = '';
      Dialog.alert({
        message: `支付完成`,
        confirmButtonText: '确认',
      }).then(() => {
        WeixinJSBridge.call('closeWindow');
      });
    }
  },
};
</script>
