<template>
  <div v-if="_show">
    <van-popup
        v-model="_show"
        :closeable="closeable"
        :close-on-click-overlay="closeable"
        :close-on-popstate="true"
        round position="bottom"
    >
      <div class="fast-cards-pay-title">快捷支付</div>

      <div class="fast-cards-pay-bank-list">
        <van-radio-group v-model="tokenNo">
          <van-cell-group>
            <van-cell
                v-for="card of fastCardsList"
                :title="card.bankName"
                clickable
                @click="tokenNo = card.tokenNo"
            >
              <template #label>
                <span>卡号: {{ card.cardId }} 电话: {{ card.telNo }}</span>
              </template>
              <template #right-icon>
                <van-radio :name="card.tokenNo" :disabled="!!paymentId"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <van-field
          v-model="smsCode"
          center
          clearable
          :border="false"
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请输入短信验证码' }]"
      >
        <template #button>
          <van-button
              size="small"
              :type="countDownShow ? 'default' : 'primary'"
              :disabled="countDownShow"
              @click="sendSmsCode"
          >
            <van-count-down
                v-show="countDownShow"
                ref="countDown"
                :time="60*1000"
                :auto-start="false"
                format="ss秒"
                @finish="onCountDownFinish"
            />
            <span v-show="!countDownShow">发送验证码</span>
          </van-button>
        </template>
      </van-field>

      <van-row gutter="20" style="margin: 1.5em;">
        <van-col v-if="showCancel" span="12">
          <van-button round block @click="cancel">
            取消
          </van-button>
        </van-col>
        <van-col :span="showCancel ? 12 : 24">
          <van-button round block type="primary" :loading="loading" @click="toFastCardPay">
            支付
          </van-button>
        </van-col>
      </van-row>
    </van-popup>
  </div>

</template>

<script>
import {
  getFastCards,
  userBalancePayment,
  qrCodeFastPay,
  normalOrderPayAgain,
  resendFastPaySmsCode,
  fastPay,
} from '@/api/normalOrder';
import { Dialog, Toast } from 'vant';

const getAllRes = { headers: { getAllResponse: true } };

export default {
  name: 'FastCardsPay',
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    payServiceName: {
      default: () => `fastPay`,
    },
    orderId: {
      default: () => null,
    },
    orderNo: {
      default: () => null,
    },
    isFirstPay: {
      type: Boolean,
      default: () => false,
    },
    closeable: {
      type: Boolean,
      default: () => true,
    },
    showCancel: {
      type: Boolean,
      default: () => true,
    },
    timeoutClose: {
      type: Boolean,
      default: () => true,
    },
    timeoutCloseTime: {
      default: () => 15 * 60 * 1000,
    },
  },
  data() {
    return {
      fastCardsList: [],
      tokenNo: null,
      paymentId: null,
      smsCode: null,

      countDownShow: false,
      loading: false,

      timer: null,
    };
  },
  computed: {
    _show: {
      get() {
        if (this.show) {
          // this.timeoutClose && this.setTimeoutClose();
          Object.assign(this, {
            fastCardsList: [],
            tokenNo: null,
            paymentId: null,
            smsCode: null,
            countDownShow: false,
          });
          this.initFastCardsList();
        }

        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      }
    },
    _isFirstPay: {
      get() {
        return this.isFirstPay;
      },
      set(val) {
        this.$emit('update:isFirstPay', val);
      }
    },
  },
  created() {

  },
  mounted() {

  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer);
  },
  methods: {
    setTimeoutClose() {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        Dialog.confirm({
          message: `支付超时，即将关闭支付。`,
          confirmButtonText: '确认',
        }).then(() => {
          this.$emit('timeout');
        });
        this._show = false;
      }, this.timeoutCloseTime);
    },
    initFastCardsList() {
      getFastCards().then(res => {
        this.fastCardsList = Array.isArray(res) ? res : [];

        if (this.fastCardsList.length)
          this.tokenNo = this.fastCardsList[0].tokenNo;
        else {
          Dialog.alert({
            message: `您需要添加银行卡`,
            confirmButtonText: '去添加',
          }).then(() => {
            this.$router.push('/quickPayMent');
          });
        }
      });
    },
    countDownStart() {
      this.countDownShow = true;
      this.$refs.countDown.start();
    },
    onCountDownFinish() {
      this.countDownShow = false;
    },
    sendSmsCode() {
      const { tokenNo } = this;
      if (!tokenNo)
        return Toast('请选择银行');

      let toast = Toast.loading({
        message: '正在发送验证码...',
        forbidClick: true,
      });

      let service = null;
      if (this.paymentId)
        service = resendFastPaySmsCode({ paymentId: this.paymentId }, { ...getAllRes });
      else if (this._isFirstPay)
        service = userBalancePayment({ id: this.orderId }, { params: { tokenNo: this.tokenNo }, ...getAllRes });
      else if (this.orderNo) {
        service = qrCodeFastPay({ orderNo: this.orderNo }, { params: { tokenNo: this.tokenNo }, ...getAllRes });
      } else
        service = normalOrderPayAgain({ id: this.orderId }, { params: { tokenNo: this.tokenNo }, ...getAllRes });

      service.then(res => {
        toast.clear();
        let { code, msg, data } = res.data || {};
        if (code === 0) {
          this.$refs.countDown.reset();
          this.countDownStart();

          if (!this.paymentId) {
            const { paymentId } = data || {};
            if (!paymentId)
              return Toast('paymentId错误，值为' + paymentId);

            this.paymentId = paymentId;
          }

          this._isFirstPay = false;

          Toast('验证码已发送');
        } else {
          // Toast(msg || '请求失败.');
          Dialog.alert({
            message: msg || '请求失败.',
            confirmButtonText: '确认',
          }).catch(() => {
          });
        }
      });


    },
    toFastCardPay() {
      const { paymentId, smsCode } = this;

      if (!paymentId)
        return Toast('请点击获取验证码按钮，并输入短信验证码');

      if (!smsCode)
        return Toast('请输入短信验证码');

      this.loading = true;
      let toast = Toast.loading({
        message: '支付中...',
        forbidClick: true,
      });

      fastPay({
        paymentId,
        smsCode,
      }, { ...getAllRes }).then(res => {
        this.loading = false;
        toast.clear();
        let { code, msg, data } = res.data || {};
        if (code === 0) {
          Toast('支付成功');
          this.$emit('success');
        } else {
          // Toast(msg || '请求失败.');
          Dialog.alert({
            message: msg || '请求失败.',
            confirmButtonText: '确认',
          }).catch(() => {
          });
        }
      });

    },

    cancel() {
      this._show = false;
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="less">
.fast-cards-pay-title {
  padding: 16px;
  font-size: 16px;
  line-height: 22px;
  color: #666;
}

.fast-cards-pay-bank-list {
  max-height: 61.8vh;
  overflow-y: auto;
}

</style>
